import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import Section from "../../components/Section";

export const query = graphql`
  query LandingQuery($slug: String!) {
    strapiLanding(slug: { eq: $slug }) {
      strapiId
      title
      peopleOnlineWidget
      withoutMenuBar
      notVisibleInCourses
      SEO {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
          url
        }
      }
      form {
        title
        spreadsheetId
        ctaText
        success
        error
        nameSurname {
          text
          placeholder
          required
          display
        }
        phone {
          text
          placeholder
          required
          display
        }
        email {
          text
          placeholder
          required
          display
        }
        recommendationNameSurname {
          text
          placeholder
          required
          display
        }
        recommendationPhone {
          text
          placeholder
          required
          display
        }
        additionalInfo {
          text
          placeholder
          required
          display
        }
        discountCode {
          text
          placeholder
          required
          display
        }
        agreement {
          text
          required
        }
        thankYouPagePath
      }
      sections
    }
  }
`;

const Landing = ({ data }) => {
  const landing = data.strapiLanding;

  return (
    <Layout
      seo={landing.SEO}
      peopleOnlineWidget={landing.peopleOnlineWidget}
      withoutMenuBar={landing.withoutMenuBar}
    >
      {landing.sections.map((section) => (
        <Section key={section.id} section={section} form={landing.form} />
      ))}
    </Layout>
  );
};

export default Landing;
